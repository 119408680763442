/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { HTMLAttributes, SVGAttributes, useEffect, useState } from 'react';
import Beak from './Beak';
import styles from './Tooltip.module.scss';
import useElementClicked from '~/lib/segment/useElementClicked';

type TooltipProps = HTMLAttributes<HTMLDivElement> &
  SVGAttributes<SVGSVGElement>;
interface TooltipComponentProps extends TooltipProps {
  disclaimer: {
    id: string;
    text:
      | (string | JSX.Element)[]
      | (JSX.Element | (string | JSX.Element)[])[]
      | string;
    textStyle?: string;
    wrapperStyle?: string;
    outerWrapperStyle?: string;
    beakStyle?: string;
    tooltipWrapperStyle?: string;
    width?: number;
    translateY?: string;
    padding?: number;
  };
  elementName?: string;
  elementPosition: string;
  elementType?: string;
  idx?: number;
  tabIndex?: number;
}

const Tooltip = ({
  tabIndex,
  disclaimer,
  elementPosition,
  idx,
  ...props
}: TooltipComponentProps) => {
  const [showToolTip, setShowToolTip] = useState(false);
  const trackToolTip = useElementClicked(
    'TOOLTIP',
    elementPosition,
    'TOOLTIP_OPENED'
  );

  const handleTooltipClose = (e: Event) => {
    const element = e.target as HTMLElement;
    if (!element.id.includes('tooltip')) {
      setShowToolTip(false);
    }
  };

  // closes tooltip if user clicks anywhere on the screen outside of the element itself
  useEffect(() => {
    if (document.getElementsByTagName('body')) {
      document.body.addEventListener('click', (e) => {
        handleTooltipClose(e);
      });
    }
  }, []);

  // Track the tooltip open event for Segment Element Clicked tracking
  const handleTooltipClick = (
    e: React.MouseEvent<HTMLDivElement> | React.KeyboardEvent<HTMLDivElement>,
    id: string
  ) => {
    handleTooltipPosition(id);
    setShowToolTip(true);
    trackToolTip(e.currentTarget as HTMLElement);
  };

  // ensures that the tooltip doesn't overflow off screen while keeping everything on the same line
  function handleTooltipPosition(id: string) {
    const padding = disclaimer.padding ?? 8;
    const tooltip = document.getElementById(id);
    const tooltipRect = document.getElementById(id)?.getBoundingClientRect();
    const disclaimerWidth = disclaimer.width ?? 300;
    if (tooltipRect && tooltip) {
      const tooltipRightX = tooltipRect.x + disclaimerWidth;
      if (tooltipRect.x < 0) {
        tooltip.style.transform = `translate(${-tooltipRect.x + padding}px, ${
          disclaimer.translateY ?? '25px'
        })`;
      } else if (tooltipRightX > window.innerWidth) {
        tooltip.style.transform = `translate(${
          window.innerWidth - tooltipRightX - padding
        }px, ${disclaimer.translateY ?? '25px'})`;
      }
    }
  }

  return (
    <div
      role='button'
      className={disclaimer.tooltipWrapperStyle ?? styles.tooltipWrapper}
      onKeyDown={(e) => {
        handleTooltipClick(e, disclaimer.id);
      }}
      onClick={(e) => {
        handleTooltipClick(e, disclaimer.id);
      }}
      onMouseEnter={(e) => {
        handleTooltipClick(e, disclaimer.id);
      }}
      onMouseLeave={() => {
        setShowToolTip(false);
      }}
      tabIndex={tabIndex ?? 0}
      aria-label='open tooltip'
      aria-describedby={`${disclaimer.id}-desc`}
    >
      <div
        className={disclaimer.outerWrapperStyle ?? styles.disclaimerOuter}
        id={disclaimer.id}
        // eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex
        tabIndex={tabIndex ?? 0}
      >
        {showToolTip && (
          <div>
            <div
              className={disclaimer.wrapperStyle ?? styles.disclaimerWrapper}
            >
              <div
                id={`${disclaimer.id}-desc`}
                className={disclaimer.textStyle ?? styles.disclaimer}
              >
                {disclaimer.text}
              </div>
            </div>
          </div>
        )}
      </div>

      {showToolTip && (
        <span className={disclaimer.beakStyle ?? styles.beak}>
          <Beak />
        </span>
      )}

      <svg
        id={`tooltip${elementPosition ?? ''}${idx ?? ''}`}
        width='17'
        height='16'
        viewBox='0 0 17 16'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
        {...props}
      >
        <g id={`tooltip-g-outer${elementPosition ?? ''}${idx ?? ''}`}>
          <g id={`tooltip-g-inner${elementPosition ?? ''}${idx ?? ''}`}>
            <path
              d='M7.80984 4.49996C7.43474 4.49996 7.13066 4.79843 7.13066 5.16662L7.13066 5.83329C7.13066 6.20148 7.43474 6.49996 7.80984 6.49996L8.48901 6.49996C8.86411 6.49996 9.16818 6.20148 9.16818 5.83329L9.16818 5.16662C9.16818 4.79843 8.86411 4.49996 8.48901 4.49996L7.80984 4.49996Z'
              fill='#50565E'
              id={`tooltip-path-1-${elementPosition ?? ''}${idx ?? ''}`}
            />
            <path
              fillRule='evenodd'
              clipRule='evenodd'
              d='M14.9412 8.66663C14.9412 11.9803 12.2045 14.6666 8.8286 14.6666L7.47025 14.6666C4.09437 14.6666 1.35769 11.9803 1.35769 8.66663L1.35769 7.33329C1.35769 4.01958 4.09437 1.33329 7.47025 1.33329L8.8286 1.33329C12.2045 1.33329 14.9412 4.01958 14.9412 7.33329L14.9412 8.66663ZM8.8286 13.3333L7.47025 13.3333C4.84457 13.3333 2.71603 11.244 2.71603 8.66663L2.71603 7.33329C2.71603 4.75596 4.84457 2.66663 7.47025 2.66663L8.8286 2.66663C11.4543 2.66663 13.5828 4.75596 13.5828 7.33329L13.5828 8.66663C13.5828 11.244 11.4543 13.3333 8.8286 13.3333Z'
              fill='#50565E'
              id={`tooltip-path-2-${elementPosition ?? ''}${idx ?? ''}`}
            />
            <path
              d='M8.97717 11.5L7.30576 11.5L7.30576 7.49996L8.97717 7.49996L8.97717 11.5Z'
              fill='#50565E'
              id={`tooltip-path-3-${elementPosition ?? ''}${idx ?? ''}`}
            />
          </g>
        </g>
      </svg>
    </div>
  );
};

export default Tooltip;
